import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom'
import PopupForm from '../../../../CommonComponents/PopupForm/PopupForm';
import { authContext, balanceContext } from '../../../../index';
import { observer } from 'mobx-react';
import LoginForm from '../../../Login/LoginForm';
import Cookies from 'js-cookie';
import PopupMenu from '../../../../CommonComponents/PopupMenu/PopupMenu';

function Header() {

    const [isHidden, setHidden] = useState('none');
    const [user, setUser] = useState('');
    const [isOpen, setOpen] = useState(false)


    const { authStore } = useContext(authContext);
    const {balanceStore} = useContext(balanceContext)

    useEffect(() => setUser(authStore.user), [authStore.user]);

    const handleLogout = () => {

        Cookies.remove('refreshToken');
        setHidden('none');
        localStorage.removeItem('user')
        localStorage.removeItem('token');
        authStore.logout()
        authStore.setAuth(false);
        authStore.setUser({});
    };



    const HandleClick = () => {
        isHidden === 'none' ? setHidden('') : setHidden('none');
        console.log(isHidden);
    };


    return (
        <header className='header'>

            {!authStore.isAuth && <div style={{
                display: isHidden,
                position: 'absolute',
                zIndex: '2',
                top: '0px',
                width: '100%',
                height: '100vh',
            }}

                onClick={() => HandleClick()}
            ><PopupForm child = {<LoginForm/>}></PopupForm></div>}
            <Link to='/'><div className='header__logo'></div></Link>
            <div className='header__navigation'>
                <Link className='header__navigation_link' to='/courses'>Актуальні курси</Link>
                <Link className='header__navigation_link' to='/articles'>Корисні посилання</Link>
                {/* <Link className='header__navigation_link' to='/tests'>Тестування</Link> */}
                <Link className='header__navigation_link' to='/sertificates'>Сертифікати</Link>
                {/* <Link className='header__navigation_link' to='/programs'>Програми курсів</Link> */}
                <Link className='header__navigation_link' to='/about'>Про нас</Link>
            </div>
            <div className='header__burger' onClick={() => setOpen(!isOpen)}>☰</div>
            {isOpen && <PopupMenu></PopupMenu>}
            {authStore.isAuth && <div className='header__auth_balance'> Ваш баланс: {balanceStore.balance}</div>}

            {!authStore.isAuth && <div className='header__auth__buttons'>
                <button className='button' onClick={() => HandleClick()}>Логін</button>
                <Link to='/auth/registration'><button className='button'>Реєстрація</button></Link>
            </div>}
            {authStore.isAuth &&
                <div className='header__auth__buttons'>
                    <Link className='button button-header' to='/cabinet'>Особистий кабінет</Link>
                    <div className='button button-header' onClick={() => handleLogout()}>Вийти</div>
                </div>}
        </header>
    );
}

export default observer(Header);