import React from 'react';
import Header from '../Main/Components/Header/Header';
import Footer from '../Main/Components/Footer/Footer';
import Course from './Components/Course/Course'

import { CoursesArray } from '../../Arrays/CoursesArray';
const Courses = () => {

const courses = CoursesArray

console.log(courses)
    return (
        <div className='courses'>
            <Header></Header>
            <div className='courses__info'>
                    <h5 className='courses__info_header'>АКТУАЛЬНІ КУРСИ, ЛЕКЦІЇ І ВЕБІНАРИ</h5>
                    <div className='courses__info_description'>На цій сторінці ви знайдете список всіх акуальних заходів в нашій школі</div>
            </div>
            <div className='courses__container'>
            {courses.map( el => <Course key = {el.id} courseObj = {el}></Course>)}
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Courses;