import React, { useState } from 'react';
import './certificates.scss';
import CertificateItem from './CertificateItem';

// Интерфейс для описания данных сертификата
interface Certificate {
  id: number;
  name: string;
  number: string;
  date: string;
  bearer?: string;
  description: string;
}

const CertificatesTable: React.FC = () => {
  // Пример данных сертификатов
  const initialCertificates: Certificate[] = [
    { id: 1, number: 'АА № 2127429/38', date: '29.08.2021', bearer: 'Д. Вероніка лександрівна', description: '00-00.08.2021 р.', name: '"Основи ресторанного бізнесу"' },
    { id: 2, number: 'АБ № 2127346', date: '25.08.2023', bearer: 'С. Тетяна Анатоліївна', description: '07-25.08.2023 р., 30 год., 1 кредит (ЄКТС)', name: '"Організації виробництва, сервісу та обслуговування в закладах ресторанного господарства"' },
    { id: 3, number: 'АБ № 2127347', date: '25.08.2023', bearer: 'С. Вікторія Миколаївна', description: '07-25.08.2023 р., 30 год., 1 кредит (ЄКТС) ', name: '"Організації виробництва, сервісу та обслуговування в закладах ресторанного господарства"' },
    { id: 4, number: 'АБ № 2127348', date: '25.08.2023', bearer: 'М. Марина Ігорівна', description: '07-25.08.2023 р., 30 год., 1 кредит (ЄКТС)', name: '"Організації виробництва, сервісу та обслуговування в закладах ресторанного господарства"' },
    { id: 5, number: 'АБ № 2127349', date: '05.09.2023', bearer: 'П. Оксана Іванівна', description: '25.08-05.09.2023 р., 15 год 0,5 кредити (ЄКТС)', name: '"Санітарія то гігієна"' },
    { id: 6, number: 'АА № 2127350', date: '04.08.2023', bearer: 'С. Вікторія Миколаївна', description: '17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)', name: '"Технологія виробництва кулінарної продукції"' },
    { id: 7, number: 'АА № 2127351', date: '04.08.2023', bearer: 'С. Тетяна Анатоліївна', description: '17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)', name: '"Технологія виробництва кулінарної продукції"' },
    { id: 8, number: 'АА № 2127352', date: '04.08.2023', bearer: 'М. Марина Ігорівна', description: '17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)', name: '"Технологія виробництва кулінарної продукції"' },
    { id: 9, number: 'АА № 2127353', date: '04.08.2023', bearer: 'П. Оксана Іванівна', description: '17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)', name: '"Технологія виробництва кулінарної продукції"' },
    { id: 10, number: 'АБ № 2127359', date: '02.08.2023', bearer: 'Ф. Наталія Іванівна', description: '12.07-02.08.2023 р., 30 год., 1 кредит (ЄКТС)', name: '"Устаткування закладів ресторанногогосподарства"' },
    { id: 11, number: 'АБ № 2127365', date: '22.08.2023', bearer: 'Ф. Наталія Іванівна', description: '08-22.08.2023 р., 18 год., 0,6 кредит (ЄКТС)', name: '"Особливості кухонь народів світу"' },
    { id: 12, number: 'АА № 2127355', date: '29.03.2024', bearer: 'М. Михайло Михайлович', description: '14-29.03.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)', name: '"Інноваційні методи математичного моделювання та нові можливості використання блокчейн технологій у системах управління якістю"' },
    { id: 13, number: 'АА № 2127358', date: '15.04.2024', bearer: 'C. Володимир Павлович', description: '01-15.04.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)', name: '"Особливості експлуатації і технічного сервісу технологічного обладнання харчових виробництв"' },
    { id: 14, number: 'АА № 2127360', date: '15.04.2024', bearer: 'П. Ігор Павлович', description: '01-15.04.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)', name: '"Особливості експлуатації і технічного сервісу технологічного обладнання харчових виробництв"' },  
  ];

  const [certificates, setCertificates] = useState<Certificate[]>(initialCertificates);

  return (
    <div className="certificate-list__container">
      <h1 className='certificate-list__header'>Список  виданих сертифікатів</h1>
      <div className='certificate-list__table'>
        {certificates.map(el => <CertificateItem id={el.id} name = {el.name} number={el.number} date={el.date} bearer={el.bearer} description={el.description}  ></CertificateItem>)}
      </div>

      
    </div>
  );
};

export default CertificatesTable