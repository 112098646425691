import React from 'react';
import styled from 'styled-components'

const StyledContainer = styled.div`
min-width: 400px;
max-width: 500px;
min-height: 300px;
box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
margin: 10px;
flex-grow: 1;
display: flex;
justify-content: space-around;
padding: 10px;
align-items: center;
flex-direction: column;


`

const Program = ({program, header, description}) => {

     
    return (
    <StyledContainer>
       <h3 className='program__header'>{header}</h3>
        <div className='program__description'>{description}</div>
        <a  className ='program__button' href = {program} > Открыть</a>
        </StyledContainer>
    );
};

export default Program;