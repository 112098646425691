import React from 'react';
import Header from '../Main/Components/Header/Header';
import Footer from '../Main/Components/Footer/Footer';
import program from '../Programs/program.pdf'
import Program from './Program';

import styled from 'styled-components'

const StyledHeader = styled.h1`
    width: 100%;
    text-align: center;
    margin: 20px;
    font-size: 2.4rem;
    

`


const Programs = () => {
    return (
        <div className='programs__container'>
            <Header></Header>
            <StyledHeader> Список навчальних програм </StyledHeader>
            <div className='programs__list'>
            <Program program={program} header ='Это название учебной программы' description='Это небольшой короткий текст с описанием программы курса'/>
            <Program program={program} header ='Это название учебной программы' description='Это небольшой короткий текст с описанием программы курса'/>
            <Program program={program} header ='Это название учебной программы' description='Это небольшой короткий текст с описанием программы курса'/>
            <Program program={program} header ='Это название учебной программы' description='Это небольшой короткий текст с описанием программы курса'/>
            <Program program={program} header ='Это название учебной программы' description='Это небольшой короткий текст с описанием программы курса'/>
                
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Programs;