interface Question {
  questionNumber: number | null;
  question: string;
  options: Array<string>;
  correctAnswer: string;
}

interface TestArray {
  testId: number | null;
  articleLink?: string;
  testName: string;
  testDescription: string;
  testQuestions: Array<Question>;
  time: string;
  level: String;
  price: number;
}

export const TestsArray: Array<TestArray> = [
  {
    time: "30 хв.",
    level: "базовий",
    articleLink: "/articles/article-basics",
    price: 50,
    testName: "Базові знання принципів харчової безпеки",
    testDescription: "Тест на базові знання НАССР",
    testId: 1,
    testQuestions: [
      {
        questionNumber: 1,
        question: "Система НАССР впроваджується для:",
        options: [
          "Виготовлення безпечної продукції",
          "Виготовлення сертифікованої продукції",
          "Виготовлення якісної продукції",
        ],
        correctAnswer: "Виготовлення безпечної продукції",
      },
      {
        questionNumber: 2,
        question: "Для кого потрібна система НАССР?",
        options: [
          "Для всіх операторів, які задіяні на будь якому стані виробництва, транспортування або обігу харчових продуктів",
          "Лише для виробників харчових продуктів",
          "Лише для підприємств громадського харчування",
        ],
        correctAnswer:
          "Для всіх операторів, які задіяні на будь якому стані виробництва, транспортування або обігу харчових продуктів",
      },
      {
        questionNumber: 3,
        question:
          "Хто несе відповідальність за безпечність харчових продуктів?",
        options: [
          "Державні інспектори",
          "Державні лабораторії",
          "Підприємства оператори ринку",
        ],
        correctAnswer: "Підприємства оператори ринку",
      },
      {
        questionNumber: 4,
        question:
          "Які нормативні документ регламентує гігієнічні вимоги що до поводження з харчовими продуктами?",
        options: [
          "Декларація про якість виробника",
          "Закон України «Про основні принципи та вимоги до безпечності та якості харчових продуктів",
          "Міжнародні стандарти ISO 22000",
        ],
        correctAnswer:
          "Закон України «Про основні принципи та вимоги до безпечності та якості харчових продуктів",
      },

      {
        questionNumber: 5,
        question:
          "Які небезпечні фактори можуть бути присутні у харчовому продукті?",
        options: ["Біологічні", "Фізичні", "Хімічні", "Всі вищеназвані"],
        correctAnswer: "Всі вищеназвані",
      },

      {
        questionNumber: 6,
        question: "Хто здійснює аналіз небезпечних факторів та їх імовірність?",
        options: [
          "Акредитовані лабораторії",
          "Державні інспектори під час аудиту",
          "Підприємство-оператор ринку",
        ],
        correctAnswer: "Підприємство-оператор ринку",
      },
      {
        questionNumber: 7,
        question: "Які алергени потрібно виділяти у складі продуктів",
        options: [
          "Ті, які за регламентом Європейського Союзу та Codex Alimentarius",
          "Ті, які вказані в Законі України «Про інформацію для споживачів щодо харчових продуктів»",
          "Ті, які визначив виробник харчових продуктів",
        ],
        correctAnswer:
          "Ті, які вказані в Законі України «Про інформацію для споживачів щодо харчових продуктів»",
      },
      {
        questionNumber: 8,
        question:
          "Працівник підприємства, який контактує з харчовими продуктами повинен:",
        options: [
          "Мати діячу медичну книжку",
          "Пройти навчання",
          "Обидва варіанта",
        ],
        correctAnswer: "Обидва варіанта",
      },

      {
        questionNumber: 9,
        question: "Керівник групи НАССР – це:",
        options: [
          "Голова підрозділу Держпродспоживслужби, які визначає вимоги до НАССР",
          "Державний інспектор, який здійснює аудит системи НАССР",
          "Працівник підприємства-оператора ринку, який відповідає за дієвість системи НАССР",
        ],
        correctAnswer:
          "Працівник підприємства-оператора ринку, який відповідає за дієвість системи НАССР",
      },

      {
        questionNumber: 10,
        question: "Хто перевіряє впровадження системи НАССР?",
        options: [
          "Держпродспоживслужба",
          "Міністерство Аграрної Політики України",
          "Сертифікаційний орган, акредитований НААУ",
        ],
        correctAnswer: "Держпродспоживслужба",
      },
    ],
  },

  {
    time: "30 хв.",
    level: "продвинутий",
    price: 100,
    articleLink: "/articles/article-advanced",
    testName: "Для керівників та членів групи НАССР",
    testDescription: "Тест на поглиблені знання НАССР",
    testId: 2,
    testQuestions: [
      {
        questionNumber: 1,
        question:
          "Який з наступних документів НЕ є програмою –передумовою НАССР?",
        options: [
          "Програма по контролю технологічних процесів",
          "Програма щодо виробництва та зберігання готових страв у закладах громадського харчування",
          "Програма щодо належного планування виробничих, допоміжних і побутових приміщень",
        ],
        correctAnswer:
          "Програма щодо належного планування виробничих, допоміжних і побутових приміщень",
      },
      {
        questionNumber: 2,
        question:
          "Який з наступних документів є обов’язковим для системи НАССР?",
        options: [
          "Дозвіл на виробництво продуктів тваринного походження",
          "Наказ про створення групи НАССР",
          "Сертифікат про впровадження НАССР",
        ],
        correctAnswer: "Наказ про створення групи НАССР",
      },
      {
        questionNumber: 3,
        question:
          "Чи можуть потоки рухів сировини та готової продукції пересікатися?",
        options: [
          "Ні, ніколи за жодних умов",
          "Так, за умов розділення в часі",
          "Так, за умов що буде проведена додаткова дезінфекція інвентарю",
        ],
        correctAnswer: "Так, за умов розділення в часі",
      },
      {
        questionNumber: 4,
        question:
          "Чи обов’язкове працівникам потужності проходити спеціалізоване навчання?",
        options: [
          "Навчання не потрібне, якщо працівник працює понад 3 роки",
          "Навчання обов’язкове для всіх працівників, що контактують з харчовими продуктами або токсичними речовинами",
          "Навчання потрібне лише керівнику потужності",
        ],
        correctAnswer:
          "Навчання обов’язкове для всіх працівників, що контактують з харчовими продуктами або токсичними речовинами",
      },
      {
        questionNumber: 5,
        question:
          "Які підприємства мають право застосовувати спрощений підхід до впровадження НАССР?",
        options: [
          "Ті, де не існує значних небезпечних або всі небезпечні фактори можуть ефективно керуватися програмою-передумовами",
          "Ті, які отримали експлуатаційний дозвіл в Держпродспожитслужбе",
          "Ті, які працюють  без зауважень понад 3 роки",
        ],
        correctAnswer:
          "Ті, де не існує значних небезпечних або всі небезпечні фактори можуть ефективно керуватися програмою-передумовами",
      },
      {
        questionNumber: 6,
        question:
          "Які з наведених дій є принципами НАССР (оберіть всі необхідні)?",
        options: [
          "Проведення аналізу небезпечних факторів і визначення критичних контрольних точок",
          "Проведення аналізу небезпечних факторів і встановлення корегувальних дій",
          "Встановлення корегувальних дій і визначення критичних контрольних точок",
        ],
        correctAnswer:
          "Проведення аналізу небезпечних факторів і визначення критичних контрольних точок",
      },
      {
        questionNumber: 7,
        question:
          "З яким постачальниками харчових продуктів дозволяється працювати?",
        options: [
          "З тими, що зареєстрували потужність або мають експлантаційний дозвіл",
          "З тим, що мають сертифікат впровадження НАССР, ISO 9001 або ISO 22000",
          "Лише з тими, що успішно пройшли аудит Держпродспожитслужби",
        ],
        correctAnswer:
          "З тими, що зареєстрували потужність або мають експлантаційний дозвіл",
      },
      {
        questionNumber: 8,
        question:
          "Чи має впроваджувати програми-передумов НАССР підприємство, що не виробляє харчові продукти, а лише зберігає (склад)?",
        options: ["Достатньо лише зареєструвати потужність", "Ні", "Так"],
        correctAnswer: "Так",
      },
      {
        questionNumber: 9,
        question:
          "Які критерії рекомендується використовувати при оцінці небезпечних факторів?",
        options: [
          "За критеріями виробника харчового продукту",
          "За школою імовірності та потенційної небезпеки для здоров’я",
          "За шкалою матеріальних збитків підприємства",
        ],
        correctAnswer:
          "За школою імовірності та потенційної небезпеки для здоров’я",
      },
      {
        questionNumber: 10,
        question:
          "10.	Які засоби рекомендовані використовувати для дезінфекції інвентарю, обладнання та поверхонь що контактують з харчовими продуктами?",
        options: [
          "Будь-які засоби з сертифікатом виробника",
          "Засобами, що є у реєстрі МОЗ України",
          "Імпортні засоби для промислових підприємств",
        ],
        correctAnswer: "Засобами, що є у реєстрі МОЗ України",
      },
    ],
  },
  {
    time: "30 хв.",
    level: "базовий",
    price: 40,
    articleLink: "/articles/article-toxics",
    testName: "Тест по роботі з токсичними речовинами",
    testDescription: "Тест на базові зннання по роботі з небезпечними речовинами на підприємстві",
    testId: 3,
    testQuestions: [
      {
        questionNumber: 1,
        question: "Що таке час експозиції?",
        options: [
          "Мінімальний час перебування дезінфекційного засобу на обробленій поверхні",
          "Перерва між використанням дезінфекційного засобу",
          "Час приготування розчину для миття або дезінфекції поверхонь",
        ],
        correctAnswer:
          "Мінімальний час перебування дезінфекційного засобу на обробленій поверхні",
      },
      {
        questionNumber: 2,
        question:
          "Чи можна зберігати засіб, що містить токсичні сполуки, разом з харчовими продуктами?",
        options: [
          "Можна, якщо засоби дозволені до застосування МОЗ",
          "Ні",
          "Так",
        ],
        correctAnswer: "Ні",
      },
      {
        questionNumber: 3,
        question:
          "Чи можна приймати засоби, що містять токсичні сполуки, тим самим шляхом та через ті самі двері, що і харчові продукти?",
        options: [
          "Так, за умов їх окремого зберігання",
          "Ні",
          "Так, якщо події розділенні в часі",
        ],
        correctAnswer: "Так, якщо події розділенні в часі",
      },
      {
        questionNumber: 4,
        question:
          "Як перевірити дієвість процедури миття та дезінфекції поверхонь?",
        options: [
          "Замовити сертифікаційний аудит",
          "Запросити державного аудитора Дерпродспожитслужби",
          "Зробити змиви поверхонь та віддати до лабораторії",
        ],
        correctAnswer: "Зробити змиви поверхонь та віддати до лабораторії",
      },
      {
        questionNumber: 5,
        question:
          "Які дезінфікуючі засоби дозволено використовувати на підприємстві?",
        options: [
          "Всі, які офіційно продаються",
          "Ті, які присутні у реєстрі МОЗ",
          "Ті, які рекомендовано Держпродспожитслужбою",
        ],
        correctAnswer: "Ті, які присутні у реєстрі МОЗ",
      },
    ],
  },

  {
    time: "30 хв.",
    level: "продвинутий",
    price: 100,
    testName:
      "Тест для працівників, котрі працюють з харчовими продуктами",
    testDescription: "Тест на поглиблені знання НАССР",
    testId: 4,
    testQuestions: [
      {
        questionNumber: 1,
        question:
          "Чи можуть потоки рухів сировини та готової продукції пересікатися?",
        options: [
          "Ні, ніколи за жодних умов",
          "Так, за умов розділення в часі",
          "Так, за умов що буде проведена додаткова дезінфекція інвентарю",
        ],
        correctAnswer: "Так, за умов розділення в часі",
      },
      {
        questionNumber: 2,
        question: "Хто здійснює аналіз небезпечних факторів та їх імовірність?",
        options: [
          "Акредитовані лабораторії",
          "Державні інспектори під час аудиту",
          "Підприємство-оператор ринку",
        ],
        correctAnswer: "Підприємство-оператор ринку",
      },
      {
        questionNumber: 3,
        question:
          "Які дезінфікуючі засоби дозволено використовувати на підприємстві?",
        options: [
          "Всі, які офіційно продаються",
          "Ті, які присутні у реєстрі МОЗ",
          "Ті, які рекомендовано Держпродспоживслужбою",
        ],
        correctAnswer: "Ті, які присутні у реєстрі МОЗ",
      },
      {
        questionNumber: 4,
        question:
          "Чи обов’язкове працівникам потужності проходити спеціалізоване навчання?",
        options: [
          "Навчання не потрібне, якщо працівник працює понад 3 роки",
          "Навчання обов’язкове для всіх працівників, що контактують з харчовими продуктами або токсичними речовинами",
          "Навчання потрібне лише керівнику потужності",
        ],
        correctAnswer:
          "Навчання обов’язкове для всіх працівників, що контактують з харчовими продуктами або токсичними речовинами",
      },
      {
        questionNumber: 5,
        question:
          "Працівник підприємства, який контактує з харчовими продуктами, повинен:",
        options: [
          "Мати дійсну медичну книжку",
          "Пройти навчання",
          "Обидва варіанти",
        ],
        correctAnswer: "Обидва варіанти",
      },
      {
        questionNumber: 6,
        question: "Система НАССР впроваджується для:",
        options: [
          "Виготовлення безпечної продукції",
          "Виготовлення сертифікованої продукції",
          "Виготовлення якісної продукції",
        ],
        correctAnswer: "Виготовлення безпечної продукції",
      },
      {
        questionNumber: 7,
        question:
          "Які з наведених дій є принципами НАССР (оберіть всі необхідні)?",
        options: [
          "Проведення аналізу небезпечних факторів і встановлення корегувальних дій",
          "Проведення аналізу небезпечних факторів і визначення критичних контрольних точок",
          "Встановлення корегувальних дій і визначення критичних контрольних точок",
        ],
        correctAnswer:
          "Проведення аналізу небезпечних факторів і встановлення корегувальних дій",
      },
      {
        questionNumber: 8,
        question: "Для кого потрібна система НАССР?",
        options: [
          "Для всіх операторів, які задіяні на будь-якому етапі виробництва, транспортування або обігу харчових продуктів",
          "Лише для виробників харчових продуктів",
          "Лише для підприємств громадського харчування",
        ],
        correctAnswer:
          "Для всіх операторів, які задіяні на будь-якому етапі виробництва, транспортування або обігу харчових продуктів",
      },
      {
        questionNumber: 9,
        question: "Які алергени потрібно виділяти у складі продуктів?",
        options: [
          "Ті, які за регламентом Європейського Союзу та Codex Alimentarius",
          'Ті, які вказані в Законі України "Про інформацію для споживачів щодо харчових продуктів"',
          "Ті, які визначив виробник харчових продуктів",
        ],
        correctAnswer:
          ' Ті, які вказані в Законі України "Про інформацію для споживачів щодо харчових продуктів"',
      },
      {
        questionNumber: 10,
        question:
          "Які критерії рекомендується використовувати при оцінці небезпечних факторів?",
        options: [
          "За критеріями виробника харчового продукту",
          "За шкалою імовірності та потенційної небезпеки для здоров`я",
          "За шкалою матеріальних збитків підприємства",
        ],
        correctAnswer:
          "За шкалою імовірності та потенційної небезпеки для здоров`я",
      },
    ],
  },
];
